/*Domyślny brak wyświetlania*/
#stawkaZaKmDIV, #iloscKmDIV, #dowolnaStawkaDIV{
  display: none;
}


.paraForError{
  color: red;
  font-style: italic;
  font-weight: bold;
  display: none;
}