// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
